import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "ht-user-session__actions-container"
};
const _hoisted_2 = ["href"];
const _hoisted_3 = ["href"];
const _hoisted_4 = ["href"];
import UserMenu from './UserMenu.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'Profile',
    props: {
        t: {
            type: Object,
            default: () => ({
                myZinHomeText: 'My Zin Home',
                myDashboardText: 'My Dashboard',
                joinZinText: 'Join ZIN™',
                updatePaymentText: 'Update Payment',
                instructorTrainingsText: 'Instructor Trainings',
                sixWeekProgramText: '6 Week Program',
                myTrainingsText: 'My Trainings',
                manageMyAccountText: 'Manage My Account',
                myAccountText: 'My Account',
                signInText: 'Sign In',
                signOutText: 'Sign Out',
                myAnnouncementsText: 'My Announcements',
                marketingMaterialsText: 'Marketing Materials',
                findAnInstructorGymText: 'Find An Instructor',
                manageInstructorsText: 'Manage Instructors',
                hostATrainingText: 'Host a Training',
                zumbaForGymsText: 'Zumba For Gyms',
                gymAuditionKitText: 'Gym Audition Kit',
                manageLocationsText: 'Manage Locations',
                accountSummaryText: 'Account Summary',
                gymsProfileText: 'Gyms Profile',
                findAnInstructorText: 'Find an Instructor',
                logInText: 'Log In',
                signUpText: 'Sign Up',
            })
        },
        current: { type: String, default: '' },
        canAccessMemberMenu: { type: String, default: '' },
        canAccessSixWeeksProgramProfile: { type: String, default: '' },
        canConsumerViewDashboard: { type: String, default: '' },
        canGymOwnerViewDashboard: { type: String, default: '' },
        canManageGymProfile: { type: String, default: '' },
        canManageLinkedGymInstructors: { type: String, default: '' },
        currentUserLocale: { type: String, default: '' },
        gaLabel: { type: String, default: '' },
        gymActiveLink: { type: String, default: '' },
        isAuthenticated: { type: String, default: '' },
        isTrainedNonMember: { type: String, default: '' },
        itemClass: { type: String, default: '' },
        linkClass: { type: String, default: '' },
        loginUrl: { type: String, default: '' },
        signUpUrl: { type: String, default: '' },
        profileName: { type: String, default: '' },
        shouldShowJoinLink: { type: String, default: '' },
        userHasDelinquentMembership: { type: String, default: '' },
        userHasBenefitMemberPortal: { type: String, default: '' },
        zinResponsiveLink: { type: String, default: '' },
        isAclHeaderEnabled: { type: Boolean, default: false },
        canViewStudentDashboard: {
            type: Boolean,
            default: false
        },
        isPrePaymentAccessEnabled: { type: String, default: '' },
    },
    setup(__props) {
        const props = __props;
        return (_ctx, _cache) => {
            return (props.isAuthenticated)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createElementVNode("a", {
                        class: "gh-profile-nav-name ht-user-session",
                        href: "#",
                        "data-dropdown": "nav-profile",
                        onClick: _cache[0] || (_cache[0] = _withModifiers(() => { }, ["prevent"]))
                    }, _toDisplayString(props.profileName), 1),
                    _createElementVNode("ul", {
                        id: "nav-profile",
                        class: _normalizeClass(["dropdown-mtv dd-right profile-dropdown", {
                                'pd-zin': props.canAccessMemberMenu,
                                'pd-gym': (!props.canAccessMemberMenu && props.canManageGymProfile)
                            }])
                    }, [
                        _createVNode(UserMenu, {
                            t: props.t,
                            current: props.current,
                            "can-access-member-menu": props.canAccessMemberMenu,
                            "can-access-six-weeks-program-profile": props.canAccessSixWeeksProgramProfile,
                            "can-consumer-view-dashboard": props.canConsumerViewDashboard,
                            "can-gym-owner-view-dashboard": props.canGymOwnerViewDashboard,
                            "can-manage-gym-profile": props.canManageGymProfile,
                            "can-manage-linked-gym-instructors": props.canManageLinkedGymInstructors,
                            "current-user-locale": props.currentUserLocale,
                            "ga-label": props.gaLabel,
                            "gym-active-link": props.gymActiveLink,
                            "is-authenticated": props.isAuthenticated,
                            "is-trained-non-member": props.isTrainedNonMember,
                            "item-class": props.itemClass,
                            "link-class": props.linkClass,
                            "should-show-join-link": props.shouldShowJoinLink,
                            "user-has-delinquent-membership": props.userHasDelinquentMembership,
                            "user-has-benefit-member-portal": props.userHasBenefitMemberPortal,
                            "zin-responsive-link": props.zinResponsiveLink,
                            "can-view-student-dashboard": props.canViewStudentDashboard,
                            "is-pre-payment-access-enabled": props.isPrePaymentAccessEnabled
                        }, null, 8, ["t", "current", "can-access-member-menu", "can-access-six-weeks-program-profile", "can-consumer-view-dashboard", "can-gym-owner-view-dashboard", "can-manage-gym-profile", "can-manage-linked-gym-instructors", "current-user-locale", "ga-label", "gym-active-link", "is-authenticated", "is-trained-non-member", "item-class", "link-class", "should-show-join-link", "user-has-delinquent-membership", "user-has-benefit-member-portal", "zin-responsive-link", "can-view-student-dashboard", "is-pre-payment-access-enabled"])
                    ], 2)
                ], 64))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    (props.isAclHeaderEnabled)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                            _createElementVNode("a", {
                                class: "ht-user-session",
                                href: props.loginUrl
                            }, _toDisplayString(props.t.logInText), 9, _hoisted_2),
                            _createElementVNode("a", {
                                class: "ht-user-session",
                                href: props.signUpUrl
                            }, _toDisplayString(props.t.signUpText), 9, _hoisted_3)
                        ]))
                        : (_openBlock(), _createElementBlock("a", {
                            key: 1,
                            class: "ht-user-session",
                            href: props.loginUrl,
                            "data-ga4-event-name": "sign_in",
                            "data-ga4-action": "Sign In - Button Click"
                        }, _toDisplayString(props.t.signInText), 9, _hoisted_4))
                ], 64));
        };
    }
});
