import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, createVNode as _createVNode, unref as _unref } from "vue";
const _hoisted_1 = { key: 0 };
const _hoisted_2 = ["href", "data-ga-action", "data-ga-label", "data-ga4-action", "data-ga4-details", "innerHTML"];
const _hoisted_3 = { key: 1 };
const _hoisted_4 = ["href", "data-ga-action", "data-ga-label", "data-ga4-action", "data-ga4-details"];
const _hoisted_5 = { key: 2 };
const _hoisted_6 = ["href", "data-ga-label", "data-ga4-details", "innerHTML"];
const _hoisted_7 = { key: 3 };
const _hoisted_8 = ["href", "data-ga-label", "data-ga4-details", "innerHTML"];
const _hoisted_9 = { key: 0 };
const _hoisted_10 = ["href", "data-ga-action", "data-ga-label", "data-ga4-action", "data-ga4-details"];
const _hoisted_11 = { key: 1 };
const _hoisted_12 = ["href", "data-ga-action", "data-ga-label", "data-ga4-action", "data-ga4-details"];
const _hoisted_13 = { key: 2 };
const _hoisted_14 = ["href", "data-ga-label", "data-ga4-details", "innerHTML"];
const _hoisted_15 = { key: 3 };
const _hoisted_16 = ["href", "data-ga-label", "data-ga4-details", "innerHTML"];
const _hoisted_17 = { key: 2 };
const _hoisted_18 = ["href", "data-ga-action", "data-ga-label", "data-ga4-action", "data-ga4-details"];
const _hoisted_19 = { key: 3 };
const _hoisted_20 = { key: 4 };
const _hoisted_21 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_22 = { key: 5 };
const _hoisted_23 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_24 = { key: 6 };
const _hoisted_25 = ["href", "data-ga-action", "data-ga-label", "data-ga4-action", "data-ga4-details"];
const _hoisted_26 = { key: 7 };
const _hoisted_27 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_28 = ["href", "data-ga-action", "data-ga-label", "data-ga4-action", "data-ga4-details"];
import { computed } from 'vue';
import Gym from './Gym.vue';
import { resetMixpanel } from '@ts/Util/analytics';
export default /*@__PURE__*/ _defineComponent({
    __name: 'UserMenu',
    props: {
        t: {
            type: Object,
            default: () => ({
                syncHomeText: 'SYNC Home',
                joinSyncText: 'Join SYNC',
                zinHomeText: 'ZIN™ Home',
                myDashboardText: 'My Dashboard',
                activityAsAStudentText: 'Activity as a student',
                activityText: 'Activity',
                joinZinText: 'Join ZIN™',
                updatePaymentText: 'Update Payment',
                instructorTrainingsText: 'Instructor Trainings',
                sixWeekProgramText: '6 Week Program',
                myTrainingsText: 'My Trainings',
                manageMyAccountText: 'Manage My Account',
                myAccountText: 'My Account',
                signOutText: 'Sign Out',
                myAnnouncementsText: 'My Announcements',
                marketingMaterialsText: 'Marketing Materials',
                findAnInstructorGymText: 'Find An Instructor',
                manageInstructorsText: 'Manage Instructors',
                hostATrainingText: 'Host a Training',
                zumbaForGymsText: 'Zumba For Gyms',
                gymAuditionKitText: 'Gym Audition Kit',
                manageLocationsText: 'Manage Locations',
                accountSummaryText: 'Account Summary',
                gymsProfileText: 'Gyms Profile',
            })
        },
        current: { type: String, default: '' },
        currentUserLocale: { type: String, default: 'en-US' },
        userHasBenefitMemberPortal: { type: String, default: '' },
        canGymOwnerViewDashboard: { type: String, default: '' },
        canConsumerViewDashboard: { type: String, default: '' },
        shouldShowJoinLink: { type: String, default: '' },
        userHasDelinquentMembership: { type: String, default: '' },
        isPrePaymentAccessEnabled: { type: String, default: '' },
        isTrainedNonMember: { type: String, default: '' },
        canAccessSixWeeksProgramProfile: { type: String, default: '' },
        gaLabel: { type: String, default: '' },
        isAuthenticated: { type: String, default: '' },
        canAccessMemberMenu: { type: String, default: '' },
        canManageGymProfile: { type: String, default: '' },
        zinResponsiveLink: { type: String, default: '' },
        canManageLinkedGymInstructors: { type: String, default: '' },
        itemClass: { type: String, default: '' },
        linkClass: { type: String, default: '' },
        gymActiveLink: { type: String, default: '' },
        canViewStudentDashboard: {
            type: Boolean,
            default: false
        }
    },
    setup(__props) {
        const url = computed(() => props.current === 'strong' ? '/trainings/nearby' : '/trainings/expand-career');
        const studentDashboardText = computed(() => props.userHasBenefitMemberPortal ? props.t.activityAsAStudentText : props.t.activityText);
        const props = __props;
        return (_ctx, _cache) => {
            return (props.isAuthenticated)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    (props.current === 'zumba')
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            (props.userHasBenefitMemberPortal)
                                ? (_openBlock(), _createElementBlock("li", _hoisted_1, [
                                    _createElementVNode("a", {
                                        href: '/' + props.currentUserLocale + '/dashboard',
                                        "data-ga-category": "User Navigation Menu",
                                        "data-ga-action": props.t.zinHomeText,
                                        "data-ga-label": props.gaLabel,
                                        "data-ga4-event-name": "user_navigation_menu",
                                        "data-ga4-action": props.t.zinHomeText,
                                        "data-ga4-details": props.gaLabel,
                                        innerHTML: props.t.zinHomeText
                                    }, null, 8, _hoisted_2)
                                ]))
                                : ((props.canManageGymProfile && props.canGymOwnerViewDashboard) || (!props.canManageGymProfile && props.canConsumerViewDashboard))
                                    ? (_openBlock(), _createElementBlock("li", _hoisted_3, [
                                        (props.canGymOwnerViewDashboard)
                                            ? (_openBlock(), _createElementBlock("a", {
                                                key: 0,
                                                href: '/' + props.currentUserLocale + '/dashboard',
                                                "data-ga-category": "User Navigation Menu",
                                                "data-ga-action": props.t.myDashboardText,
                                                "data-ga-label": props.gaLabel,
                                                "data-ga4-event-name": "user_navigation_menu",
                                                "data-ga4-action": props.t.myDashboardText,
                                                "data-ga4-details": props.gaLabel
                                            }, _toDisplayString(props.t.myDashboardText), 9, _hoisted_4))
                                            : _createCommentVNode("", true)
                                    ]))
                                    : _createCommentVNode("", true),
                            (props.shouldShowJoinLink)
                                ? (_openBlock(), _createElementBlock("li", _hoisted_5, [
                                    _createElementVNode("a", {
                                        href: '/' + props.currentUserLocale + '/dashboard',
                                        "data-ga-category": "User Navigation Menu",
                                        "data-ga-action": "Join ZIN",
                                        "data-ga-label": props.gaLabel,
                                        "data-ga4-event-name": "user_navigation_menu",
                                        "data-ga4-action": "Join ZIN",
                                        "data-ga4-details": props.gaLabel,
                                        innerHTML: props.t.joinZinText
                                    }, null, 8, _hoisted_6)
                                ]))
                                : (props.userHasDelinquentMembership)
                                    ? (_openBlock(), _createElementBlock("li", _hoisted_7, [
                                        _createElementVNode("a", {
                                            href: '/' + props.currentUserLocale + (props.isPrePaymentAccessEnabled ? '/membership/pay' : '/dashboard'),
                                            "data-ga-category": "User Navigation Menu",
                                            "data-ga-action": "Update Payment",
                                            "data-ga-label": props.gaLabel,
                                            "data-ga4-event-name": "user_navigation_menu",
                                            "data-ga4-action": "Update Payment",
                                            "data-ga4-details": props.gaLabel,
                                            innerHTML: props.t.updatePaymentText
                                        }, null, 8, _hoisted_8)
                                    ]))
                                    : _createCommentVNode("", true)
                        ], 64))
                        : (props.current === 'strong')
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                (props.userHasBenefitMemberPortal)
                                    ? (_openBlock(), _createElementBlock("li", _hoisted_9, [
                                        _createElementVNode("a", {
                                            href: '/' + props.currentUserLocale + '/dashboard',
                                            "data-ga-category": "User Navigation Menu",
                                            "data-ga-action": props.t.syncHomeText,
                                            "data-ga-label": props.gaLabel,
                                            "data-ga4-event-name": "user_navigation_menu",
                                            "data-ga4-action": props.t.syncHomeText,
                                            "data-ga4-details": props.gaLabel
                                        }, _toDisplayString(props.t.syncHomeText), 9, _hoisted_10)
                                    ]))
                                    : (_openBlock(), _createElementBlock("li", _hoisted_11, [
                                        _createElementVNode("a", {
                                            href: '/' + props.currentUserLocale + '/dashboard',
                                            "data-ga-category": "User Navigation Menu",
                                            "data-ga-action": props.t.myDashboardText,
                                            "data-ga-label": props.gaLabel,
                                            "data-ga4-event-name": "user_navigation_menu",
                                            "data-ga4-action": props.t.myDashboardText,
                                            "data-ga4-details": props.gaLabel
                                        }, _toDisplayString(props.t.myDashboardText), 9, _hoisted_12)
                                    ])),
                                (props.shouldShowJoinLink)
                                    ? (_openBlock(), _createElementBlock("li", _hoisted_13, [
                                        _createElementVNode("a", {
                                            href: '/' + props.currentUserLocale + '/member-benefits',
                                            "data-ga-category": "User Navigation Menu",
                                            "data-ga-action": "Join SYNC",
                                            "data-ga-label": props.gaLabel,
                                            "data-ga4-event-name": "user_navigation_menu",
                                            "data-ga4-action": "Join SYNC",
                                            "data-ga4-details": props.gaLabel,
                                            innerHTML: props.t.joinSyncText
                                        }, null, 8, _hoisted_14)
                                    ]))
                                    : _createCommentVNode("", true),
                                (props.userHasDelinquentMembership && props.isPrePaymentAccessEnabled)
                                    ? (_openBlock(), _createElementBlock("li", _hoisted_15, [
                                        _createElementVNode("a", {
                                            href: '/' + props.currentUserLocale + '/membership/pay',
                                            "data-ga-category": "User Navigation Menu",
                                            "data-ga-action": "Update Payment",
                                            "data-ga-label": props.gaLabel,
                                            "data-ga4-event-name": "user_navigation_menu",
                                            "data-ga4-action": "Update Payment",
                                            "data-ga4-details": props.gaLabel,
                                            innerHTML: props.t.updatePaymentText
                                        }, null, 8, _hoisted_16)
                                    ]))
                                    : _createCommentVNode("", true)
                            ], 64))
                            : _createCommentVNode("", true),
                    (props.isTrainedNonMember)
                        ? (_openBlock(), _createElementBlock("li", _hoisted_17, [
                            _createElementVNode("a", {
                                href: '/' + props.currentUserLocale + url.value,
                                "data-ga-category": "User Navigation Menu",
                                "data-ga-action": props.t.instructorTrainingsText,
                                "data-ga-label": props.gaLabel,
                                "data-ga4-event-name": "user_navigation_menu",
                                "data-ga4-action": props.t.instructorTrainingsText,
                                "data-ga4-details": props.gaLabel
                            }, _toDisplayString(props.t.instructorTrainingsText), 9, _hoisted_18)
                        ]))
                        : (props.canManageGymProfile)
                            ? (_openBlock(), _createElementBlock("li", _hoisted_19, [
                                _createVNode(Gym, {
                                    t: props.t,
                                    "can-manage-gym-profile": props.canManageGymProfile,
                                    "can-manage-linked-gym-instructors": props.canManageLinkedGymInstructors,
                                    "current-user-locale": props.currentUserLocale,
                                    "ga-label": props.gaLabel,
                                    "gym-active-link": props.gymActiveLink,
                                    "item-class": props.itemClass,
                                    "link-class": props.linkClass
                                }, null, 8, ["t", "can-manage-gym-profile", "can-manage-linked-gym-instructors", "current-user-locale", "ga-label", "gym-active-link", "item-class", "link-class"])
                            ]))
                            : _createCommentVNode("", true),
                    (props.current === 'zumba' && props.canAccessSixWeeksProgramProfile)
                        ? (_openBlock(), _createElementBlock("li", _hoisted_20, [
                            _createElementVNode("a", {
                                href: '/' + props.currentUserLocale + '/6week',
                                "data-ga-category": "User Navigation Menu",
                                "data-ga-action": "Six Weeks Program",
                                "data-ga-label": props.gaLabel,
                                "data-ga4-event-name": "user_navigation_menu",
                                "data-ga4-action": "Six Weeks Program",
                                "data-ga4-details": props.gaLabel
                            }, _toDisplayString(props.t.sixWeekProgramText), 9, _hoisted_21)
                        ]))
                        : _createCommentVNode("", true),
                    (!props.canAccessMemberMenu && props.canConsumerViewDashboard)
                        ? (_openBlock(), _createElementBlock("li", _hoisted_22, [
                            _createElementVNode("a", {
                                href: '/' + props.currentUserLocale + '/training/history',
                                "data-ga-category": "User Navigation Menu",
                                "data-ga-action": "Training History",
                                "data-ga-label": props.gaLabel,
                                "data-ga4-event-name": "user_navigation_menu",
                                "data-ga4-action": "Training History",
                                "data-ga4-details": props.gaLabel
                            }, _toDisplayString(props.t.myTrainingsText), 9, _hoisted_23)
                        ]))
                        : _createCommentVNode("", true),
                    (!props.canManageGymProfile)
                        ? (_openBlock(), _createElementBlock("li", _hoisted_24, [
                            _createElementVNode("a", {
                                href: '/' + props.currentUserLocale + '/' + props.zinResponsiveLink,
                                "data-ga-category": "User Navigation Menu",
                                "data-ga-action": props.canAccessMemberMenu ? props.t.manageMyAccountText : props.t.myAccountText,
                                "data-ga-label": props.gaLabel,
                                "data-ga4-event-name": "user_navigation_menu",
                                "data-ga4-action": props.canAccessMemberMenu ? props.t.manageMyAccountText : props.t.myAccountText,
                                "data-ga4-details": props.gaLabel
                            }, _toDisplayString(props.canAccessMemberMenu ? props.t.manageMyAccountText : props.t.myAccountText), 9, _hoisted_25)
                        ]))
                        : _createCommentVNode("", true),
                    (props.canViewStudentDashboard)
                        ? (_openBlock(), _createElementBlock("li", _hoisted_26, [
                            _createElementVNode("a", {
                                href: '/' + props.currentUserLocale + '/student_dashboard',
                                "data-ga-category": "User Navigation Menu",
                                "data-ga-action": "Student Dashboard",
                                "data-ga-label": props.gaLabel,
                                "data-ga4-event-name": "user_navigation_menu",
                                "data-ga4-action": "Student Dashboard",
                                "data-ga4-details": props.gaLabel
                            }, _toDisplayString(studentDashboardText.value), 9, _hoisted_27)
                        ]))
                        : _createCommentVNode("", true),
                    _createElementVNode("li", null, [
                        _createElementVNode("a", {
                            href: '/' + props.currentUserLocale + '/logout',
                            "data-ga-category": "User Navigation Menu",
                            "data-ga-action": props.t.signOutText,
                            "data-ga-label": props.gaLabel,
                            "data-ga4-event-name": "user_navigation_menu",
                            "data-ga4-action": props.t.signOutText,
                            "data-ga4-details": props.gaLabel,
                            onClick: _cache[0] || (_cache[0] =
                                //@ts-ignore
                                (...args) => (_unref(resetMixpanel) && _unref(resetMixpanel)(...args)))
                        }, _toDisplayString(props.t.signOutText), 9, _hoisted_28)
                    ])
                ], 64))
                : _createCommentVNode("", true);
        };
    }
});
