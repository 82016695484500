import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_2 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_3 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_4 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_5 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_6 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_7 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_8 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_9 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_10 = ["href", "data-ga-label", "data-ga4-details"];
export default /*@__PURE__*/ _defineComponent({
    __name: 'Gym',
    props: {
        t: {
            type: Object,
            default: () => ({
                myAnnouncementsText: 'My Announcements',
                marketingMaterialsText: 'Marketing Materials',
                findAnInstructorGymText: 'Find An Instructor',
                manageInstructorsText: 'Manage Instructors',
                hostATrainingText: 'Host a Training',
                zumbaForGymsText: 'Zumba For Gyms',
                gymAuditionKitText: 'Gym Audition Kit',
                manageLocationsText: 'Manage Locations',
                accountSummaryText: 'Account Summary',
                gymsProfileText: 'Gyms Profile',
            })
        },
        canManageGymProfile: { type: String, default: '' },
        canManageLinkedGymInstructors: { type: String, default: '' },
        currentUserLocale: { type: String, default: '' },
        gaLabel: { type: String, default: '' },
        gymActiveLink: { type: String, default: '' },
        itemClass: { type: String, default: '' },
        linkClass: { type: String, default: '' },
    },
    setup(__props) {
        const props = __props;
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
                (props.canManageGymProfile)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createElementVNode("li", {
                            class: _normalizeClass(props.itemClass)
                        }, [
                            _createElementVNode("a", {
                                class: _normalizeClass({
                                    'js-active-item active-item': props.gymActiveLink == '/announcement',
                                    [props.linkClass]: true
                                }),
                                href: '/' + props.currentUserLocale + '/announcement',
                                "data-ga-category": "Navigation Menu",
                                "data-ga-action": "Gym_Announcements",
                                "data-ga-label": props.gaLabel,
                                "data-ga4-event-name": "navigation_menu",
                                "data-ga4-action": "Gym_Announcements",
                                "data-ga4-details": props.gaLabel
                            }, _toDisplayString(props.t.myAnnouncementsText), 11, _hoisted_1)
                        ], 2),
                        _createElementVNode("li", {
                            class: _normalizeClass(props.itemClass)
                        }, [
                            _createElementVNode("a", {
                                class: _normalizeClass({
                                    'js-active-item active-item': props.gymActiveLink == '/gym-marketing',
                                    [props.linkClass]: true
                                }),
                                href: '/' + props.currentUserLocale + '/gym-marketing',
                                "data-ga-category": "Navigation Menu",
                                "data-ga-action": "Gym_Marketing Materials",
                                "data-ga-label": props.gaLabel,
                                "data-ga4-event-name": "navigation_menu",
                                "data-ga4-action": "Gym_Marketing Materials",
                                "data-ga4-details": props.gaLabel
                            }, _toDisplayString(props.t.marketingMaterialsText), 11, _hoisted_2)
                        ], 2),
                        _createElementVNode("li", {
                            class: _normalizeClass(props.itemClass)
                        }, [
                            _createElementVNode("a", {
                                class: _normalizeClass({
                                    'js-active-item active-item': props.gymActiveLink == '/instructor',
                                    [props.linkClass]: true
                                }),
                                href: '/' + props.currentUserLocale + '/instructor',
                                "data-ga-category": "Navigation Menu",
                                "data-ga-action": "Gym_Find an Instructor",
                                "data-ga-label": props.gaLabel,
                                "data-ga4-event-name": "navigation_menu",
                                "data-ga4-action": "Gym_Find an Instructor",
                                "data-ga4-details": props.gaLabel
                            }, _toDisplayString(props.t.findAnInstructorGymText), 11, _hoisted_3)
                        ], 2)
                    ], 64))
                    : _createCommentVNode("", true),
                (props.canManageLinkedGymInstructors)
                    ? (_openBlock(), _createElementBlock("li", {
                        key: 1,
                        class: _normalizeClass(props.itemClass)
                    }, [
                        _createElementVNode("a", {
                            class: _normalizeClass({
                                'js-active-item active-item': props.gymActiveLink == '/gym/instructor',
                                [props.linkClass]: true
                            }),
                            href: '/' + props.currentUserLocale + '/gym/instructor',
                            "data-ga-category": "Navigation Menu",
                            "data-ga-action": "Gym_Manage Instructors",
                            "data-ga-label": props.gaLabel,
                            "data-ga4-event-name": "navigation_menu",
                            "data-ga4-action": "Gym_Manage Instructors",
                            "data-ga4-details": props.gaLabel
                        }, _toDisplayString(props.t.manageInstructorsText), 11, _hoisted_4)
                    ], 2))
                    : _createCommentVNode("", true),
                (props.canManageGymProfile)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                        _createElementVNode("li", {
                            class: _normalizeClass(props.itemClass)
                        }, [
                            _createElementVNode("a", {
                                class: _normalizeClass({
                                    'js-active-item active-item': props.gymActiveLink == '/clubs/host_a_training',
                                    [props.linkClass]: true
                                }),
                                href: '/' + props.currentUserLocale + '/clubs/host_a_training',
                                "data-ga-category": "Navigation Menu",
                                "data-ga-action": "Gym_Host a Training",
                                "data-ga-label": props.gaLabel,
                                "data-ga4-event-name": "navigation_menu",
                                "data-ga4-action": "Gym_Host a Training",
                                "data-ga4-details": props.gaLabel
                            }, _toDisplayString(props.t.hostATrainingText), 11, _hoisted_5)
                        ], 2),
                        _createElementVNode("li", {
                            class: _normalizeClass(props.itemClass)
                        }, [
                            _createElementVNode("a", {
                                class: _normalizeClass({
                                    'js-active-item active-item': props.gymActiveLink == '/clubs/business_perks',
                                    [props.linkClass]: true
                                }),
                                href: '/' + props.currentUserLocale + '/clubs/business_perks',
                                "data-ga-category": "Navigation Menu",
                                "data-ga-action": "Gym_Zumba for Gyms",
                                "data-ga-label": props.gaLabel,
                                "data-ga4-event-name": "navigation_menu",
                                "data-ga4-action": "Gym_Zumba for Gyms",
                                "data-ga4-details": props.gaLabel
                            }, _toDisplayString(props.t.zumbaForGymsText), 11, _hoisted_6)
                        ], 2),
                        _createElementVNode("li", {
                            class: _normalizeClass(props.itemClass)
                        }, [
                            _createElementVNode("a", {
                                class: _normalizeClass({
                                    'js-active-item active-item': props.gymActiveLink == '/gym/auditionkits',
                                    [props.linkClass]: true
                                }),
                                href: '/' + props.currentUserLocale + '/gym/auditionkits/',
                                "data-ga-category": "Navigation Menu",
                                "data-ga-action": "Gym_Audition Kit",
                                "data-ga-label": props.gaLabel,
                                "data-ga4-event-name": "navigation_menu",
                                "data-ga4-action": "Gym_Audition Kit",
                                "data-ga4-details": props.gaLabel
                            }, _toDisplayString(props.t.gymAuditionKitText), 11, _hoisted_7)
                        ], 2),
                        _createElementVNode("li", {
                            class: _normalizeClass(props.itemClass)
                        }, [
                            _createElementVNode("a", {
                                class: _normalizeClass({
                                    'js-active-item active-item': props.gymActiveLink == '/gym/location',
                                    [props.linkClass]: true
                                }),
                                href: '/' + props.currentUserLocale + '/gym/location',
                                "data-ga-category": "Navigation Menu",
                                "data-ga-action": "Gym_Manage Locations",
                                "data-ga-label": props.gaLabel,
                                "data-ga4-event-name": "navigation_menu",
                                "data-ga4-action": "Gym_Manage Locations",
                                "data-ga4-details": props.gaLabel
                            }, _toDisplayString(props.t.manageLocationsText), 11, _hoisted_8)
                        ], 2),
                        _createElementVNode("li", {
                            class: _normalizeClass(props.itemClass)
                        }, [
                            _createElementVNode("a", {
                                class: _normalizeClass({
                                    'js-active-item active-item': props.gymActiveLink == '/account',
                                    [props.linkClass]: true
                                }),
                                href: '/' + props.currentUserLocale + '/account',
                                "data-ga-category": "Navigation Menu",
                                "data-ga-action": "Gym_Account Summary",
                                "data-ga-label": props.gaLabel,
                                "data-ga4-event-name": "navigation_menu",
                                "data-ga4-action": "Gym_Account Summary",
                                "data-ga4-details": props.gaLabel
                            }, _toDisplayString(props.t.accountSummaryText), 11, _hoisted_9)
                        ], 2),
                        _createElementVNode("li", {
                            class: _normalizeClass(props.itemClass)
                        }, [
                            _createElementVNode("a", {
                                class: _normalizeClass({
                                    'js-active-item active-item': props.gymActiveLink == '/gym/account',
                                    [props.linkClass]: true
                                }),
                                href: '/' + props.currentUserLocale + '/gym/account',
                                "data-ga-category": "Navigation Menu",
                                "data-ga-action": "Gym_Gym Profile",
                                "data-ga-label": props.gaLabel,
                                "data-ga4-event-name": "navigation_menu",
                                "data-ga4-action": "Gym_Gym Profile",
                                "data-ga4-details": props.gaLabel
                            }, _toDisplayString(props.t.gymsProfileText), 11, _hoisted_10)
                        ], 2)
                    ], 64))
                    : _createCommentVNode("", true)
            ], 64));
        };
    }
});
